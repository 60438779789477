import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';
import urlSlug from 'url-slug';
import FullCalendar from '@fullcalendar/react';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import ReactTooltip from 'react-tooltip';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import { items } from '../../utils';

export const query = graphql`
  {
    site {
      siteMetadata {
        calendar {
          googleCalendarApiKey
          sources {
            sunday
            local
            conference
          }
        }
      }
    }
    api {
      conferences(includeUnlisted: true) {
        edges {
          node {
            id
            title
            slug
            icalUid
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const conferences = items(data.api.conferences);
  const conferencesMap = conferences.reduce((obj, conference) => {
    const [id] = conference.icalUid.split('@');
    return { ...obj, [id]: conference };
  }, {});

  const handleClick = ({ jsEvent, event }) => {
    jsEvent.preventDefault();
    navigate(event.url);
  };

  const showTooltip = ({ event, el }) => {
    el.setAttribute('data-tip', event.title);
    ReactTooltip.rebuild();
  };

  const { googleCalendarApiKey, sources } = data.site.siteMetadata.calendar;

  const calendarProps = {
    timeZone: 'US/Mountain',
    googleCalendarApiKey,
    eventSources: Object.entries(sources).map(([source, googleCalendarId]) => ({
      googleCalendarId,
      eventDataTransform: (eventData) => {
        const { id, title } = eventData;

        let url;
        if (source === 'conference' && id in conferencesMap) {
          url = `/events/conferences/${conferencesMap[id].slug}`;
        } else if (source === 'sunday') {
          url = '/events/sunday';
        } else {
          url = `/events/calendar/${id.slice(0, 8)}/${urlSlug(title)}`;
        }

        return {
          ...eventData,
          url,
        };
      },
    })),
    eventClick: handleClick,
  };

  return (
    <Layout>
      <SEO title="Calendar" />
      <ReactTooltip />
      <Box textAlign="center" mb={6}>
        <Typography variant="h1">Calendar</Typography>
      </Box>

      <Box mb={8}>
        <Divider />
      </Box>

      <Hidden mdUp>
        <FullCalendar
          plugins={[momentTimezonePlugin, listPlugin, googleCalendarPlugin]}
          initialView="listMonth"
          height="auto"
          titleFormat={{ month: 'short', year: 'numeric' }}
          {...calendarProps}
        />
      </Hidden>

      <Hidden smDown>
        <FullCalendar
          plugins={[momentTimezonePlugin, dayGridPlugin, googleCalendarPlugin]}
          initialView="dayGridMonth"
          eventMouseEnter={showTooltip}
          {...calendarProps}
        />
      </Hidden>
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;
